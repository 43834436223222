<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      produtos: null,
      produto: null,
      modal: false,

      categorias: null,
      categoria: 'all',
    };
  },
  methods: {
    getProdutos() {
      this.produtos = null;
      this.produto = null;
      this.loading = true;
      this.errored = false;
      this.items = null;

      api
        .get("produtos?categoria=" + this.categoria)
        .then((res) => {
          if (res.data.status == "success") {
            this.produtos = res.data.list;
          }
        })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCategorias() {
      api
        .get("categorias")
        .then((res) => {
          if (res.data.status == "success") {
            this.categorias = res.data.list;
          }
        })
    }
  },
  mounted() {
    this.getProdutos();
    this.getCategorias();
  },
  watch: {
    categoria: function () {
      this.getProdutos();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Produtos</h3>
        <p></p>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <b-dropdown variant="outline-light">
        <template #button-content>
          Categorias
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form>
          <b-form-radio v-model="categoria" value="all">Todos</b-form-radio>
          <b-form-radio v-for="(cat, index) in categorias" :key="index" v-model="categoria" :value="cat.slug">{{ cat.name }}</b-form-radio>
        </b-dropdown-form>
      </b-dropdown>
    </div>

    <div v-if="!produtos" class="card">
      <div class="card-body text-center"><b-spinner class="align-middle" variant="default" role="status"></b-spinner></div>
    </div>

    <div v-else-if="produtos && produtos.length == 0" class="card">
      <div class="card-body text-center">Nenhum produto encontrado.</div>
    </div>

    <div v-else class="produtos">
      <div class="produto" v-for="(row, index) in produtos" :key="index">
        <button class="produto-ver" v-on:click="modal = true; produto = row">
          <div class="card">
            <div class="card-body">
              <img class="produto-image" :src="row.image" />
              <span class="bg-soft-dark rounded font-size-11 text-uppercase px-1">{{ row.category }}</span>
              <div class="mt-2">
                <h6 class="mb-1 produto-name">{{ row.name }}</h6>
                <h4 class="mb-0 produto-price">{{ row.price | currency }}</h4>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>

    <b-modal v-model="modal" modal-class="modal-right" hide-header hide-footer>
      <div v-if="produto">
        <img class="w-100 rounded mb-3" :src="produto.image" />
        <div class="d-flex justify-content-between">
          <h5 class="mb-0">{{ produto.name }}</h5>
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="produto.name" v-on:click="$toast.success('Copiado!')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(60, 60, 66, 1);">
              <path
                d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
              </path>
            </svg>
          </button>
        </div>
        <h2 class="mb-4">{{ produto.price | currency }}</h2>
        <div v-if="produto.description" class="d-none mb-4 card card-description">
          <div class="card-body" v-html="produto.description">
          </div>
          <div class="card-footer bg-white rounded-bottom">
            <button class="btn btn-outline-default btn-block" v-clipboard:copy="produto.description" v-on:click="$toast.success('Copiado!')">COPIAR</button>
          </div>
        </div>
        <div v-if="produto.url" class="card mb-2">
          <div class="card-body font-size-15 text-uppercase p-2 px-3 d-flex align-items-center">
            <div class="flex-fill">FOTOS E DESCRIÇÃO</div>
            <div class="ml-2">
              <a class="btn btn-link p-0 m-0" target="_blank" :href="produto.url">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                  <path d="m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z"></path>
                  <path d="M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body font-size-15 text-uppercase p-2 px-3 d-flex align-items-center">
            <div class="flex-fill">SKU</div>
            <div>
              {{ produto.sku }}
            </div>
            <div class="ml-2">
              <button class="btn btn-link p-0 m-0" v-clipboard:copy="produto.sku" v-on:click="$toast.success('Copiado!')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(60, 60, 66, 1);">
                  <path
                    d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
                  </path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body font-size-15 text-uppercase p-2 px-3 d-flex align-items-center">
            <div class="flex-fill">Peso</div>
            <div>
              {{ produto.weight }} kg
            </div>
            <div class="ml-2">
              <button class="btn btn-link p-0 m-0" v-clipboard:copy="produto.weight" v-on:click="$toast.success('Copiado!')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(60, 60, 66, 1);">
                  <path
                    d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
                  </path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body font-size-15 text-uppercase p-2 px-3 d-flex align-items-center justify-content-between">
            <div class="flex-fill">Largura</div>
            <div>
              {{ produto.dimension_package_width }} cm
            </div>
            <div class="ml-2">
              <button class="btn btn-link p-0 m-0" v-clipboard:copy="produto.dimension_package_width" v-on:click="$toast.success('Copiado!')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(60, 60, 66, 1);">
                  <path
                    d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
                  </path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body font-size-15 text-uppercase p-2 px-3 d-flex align-items-center justify-content-between">
            <div class="flex-fill">Altura</div>
            <div>
              {{ produto.dimension_package_height }} cm
            </div>
            <div class="ml-2">
              <button class="btn btn-link p-0 m-0" v-clipboard:copy="produto.dimension_package_height" v-on:click="$toast.success('Copiado!')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(60, 60, 66, 1);">
                  <path
                    d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
                  </path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body font-size-15 text-uppercase p-2 px-3 d-flex align-items-center justify-content-between">
            <div class="flex-fill">Comprimento</div>
            <div>
              {{ produto.dimension_package_length }} cm
            </div>
            <div class="ml-2">
              <button class="btn btn-link p-0 m-0" v-clipboard:copy="produto.dimension_package_length" v-on:click="$toast.success('Copiado!')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(60, 60, 66, 1);">
                  <path
                    d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
                  </path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex mt-3">
          <a class="btn btn-outline-link" v-on:click="modal = false;">Fechar <sup>ESC</sup></a>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.produtos {
  display: flex;
  flex-flow: row wrap;
  gap: 1%;
}

.produto {
  flex-basis: 19%;
}

.produto .card-body {
  min-height: 400px;
}

@media (max-width: 1450px) {
  .produtos {
    gap: 2%;
  }

  .produto {
    flex-basis: 23%;
  }

  .produto .card-body {
    min-height: 350px;
  }
}

@media (max-width: 1300px) {
  .produtos {
    gap: 2%;
  }

  .produto {
    flex-basis: 32%;
  }

  .produto .card-body {
    min-height: 360px;
  }
}

@media (max-width: 1100px) {
  .produtos {
    gap: 2%;
  }

  .produto {
    flex-basis: 32%;
  }

  .produto .card-body {
    min-height: 330px;
  }
}

@media (max-width: 500px) {
  .produtos {
    gap: 2%;
  }

  .produto {
    flex-basis: 100%;
  }
}

.produto-ver {
  border: 0;
  background: none;
  margin-bottom: 24px;
  padding: 0;
  width: 100%;
  text-align: left;
}

.produto-ver .card {
  margin: 0;
}

.produto-image {
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.card-description .card-body {
  height: 200px;
  overflow-x: auto;
}
</style>
